<template>
  <v-container>
    <div class="text-h4 mb-10">Suppliers</div>
    <div class="v-picker--full-width d-flex justify-center" v-if="loading">
      <v-progress-circular
        :size="70"
        :width="7"
        color="purple"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-simple-table>
      <template v-slot:default>
        <col style="width: 10%" />
        <col style="width: 60%" />
        <col style="width: 15%" />
        <col style="width: 15%" />
        <thead>
          <tr>
            <th class="text-left">Id</th>
            <th class="text-left">Supplier Name</th>
            <th class="text-left">Edit</th>
            <th class="text-left">Delete</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in suppliers" :key="item.id">
            <td>{{ item.id }}</td>
            <td>{{ item.supplierName }}</td>
            <td>
              <UpdateSupplierForm :bodyRequest="item" />
            </td>
            <td>
              <v-icon @click="deleteSupplierById(item)"
                >mdi-delete-outline</v-icon
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <AddSupplierForm />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AddSupplierForm from "@/components/AddSupplierForm.vue";
import UpdateSupplierForm from "@/components/UpdateSupplierForm.vue";

export default {
  name: "Suppliers",
  components: {
    AddSupplierForm,
    UpdateSupplierForm,
  },
  mounted() {
    this.getSuppliersListAction();
  },
  methods: {
    ...mapActions("supplierModule", [
      "getSuppliersListAction",
      "deleteSupplierByIdAction",
    ]),
    deleteSupplierById(supplier) {
      const confirmed = confirm(
        "Are you sure you want to delete the following supplier? \n\n " +
          supplier.supplierName
      );
      if (!confirmed) return;
      this.deleteSupplierByIdAction(supplier.id);
    },
  },
  computed: {
    ...mapGetters("supplierModule", {
      suppliers: "suppliers",
      loading: "loading",
    }),
  },
};
</script>
